import React from 'react'
import Layout from '../utils/layout'
import SEO from '../utils/seo'
import ProductSchema from '../components/Shared/Schema/Product'
import Info from '../components/KingMultiplePage/Info'
import Partners from '../components/KingMultiplePage/Partners'
import Science from '../components/KingMultiplePage/Science'
import Benefits from '../components/KingMultiplePage/Benefits'
import Reviews from '../components/KingMultiplePage/Reviews'
import Testimonials from '../components/KingMultiplePage/Testimonials'
import NoEffort from '../components/KingMultiplePage/NoEffort'
import HowItWorks from '../components/KingMultiplePage/HowItWorks'
import Doctors from '../components/KingMultiplePage/Doctors'
import BuildingBlocks from '../components/KingMultiplePage/BuildingBlocks'
import Faq from '../components/KingMultiplePage/Faq'
import FoodAndDrugAdministration from '../components/KingMultiplePage/FoodAndDrugAdministration'
import Cta from '../components/KingMultiplePage/Cta'
import Wim from '../components/KingMultiplePage/Wim'

export default ({ pageContext: { data } }) => {
  return (
    <Layout data={data} signUpUrl="https://app.maximustribe.com/registration" signUpText="Get Started">
      <SEO {...data.seo} />
      <ProductSchema {...data.productSchema} />
      <Info info={data.info} />
      <Partners partners={data.partners} />
      <Science science={data.science} table={data.table} />
      <Benefits benefits={data.benefits} />
      <Reviews reviews={data.reviews} />
      <Testimonials testimonials={data.testimonials} />
      <NoEffort noEffort={data.noEffort} />
      <HowItWorks howItWorks={data.howItWorks} />
      <Doctors doctors={data.doctors} />
      <BuildingBlocks buildingBlocks={data.buildingBlocks} />
      <Faq faq={data.faq} />
      <FoodAndDrugAdministration foodAndDrugAdministration={data.foodAndDrugAdministration} />
      <Cta cta={data.cta} />
      <Wim wim={data.wim} />
    </Layout>
  )
}
