import React from 'react'
import cx from 'classnames'
import { srcSetProps, sanityImageUrl } from '../../../utils/format'
import styles from './style.module.sass'

export default ({ noEffort }) => (
  <div className={styles.noEffort}>
    <img {...srcSetProps(sanityImageUrl(noEffort.image))} className={styles.background} />
    <div className={styles.wrap}>
      <div className={styles.content}>
        <h2 className={styles.title}>{noEffort.title}</h2>
        <div className={styles.list}>
          {noEffort.list &&
            noEffort.list.map(item => (
              <div className={styles.item} key={item._key}>
                <img {...srcSetProps(sanityImageUrl(item.image))} className={styles.image} />
                <p className={styles.text}>{item.text}</p>
              </div>
            ))}
        </div>
      </div>
    </div>
  </div>
)
