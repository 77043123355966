import React from 'react'
import { srcSetProps, sanityImageUrl } from '../../../utils/format'
import cx from 'classnames'
import Slider from 'react-slick'
import styles from './style.module.sass'

export default ({ howItWorks }) => {
  const sliderSettings = {
    dots: false,
    autoplay: false,
    slidesToShow: 1.3,
    infinite: false,
    responsive: [
      {
        breakpoint: 700,
      },
      {
        breakpoint: 10000,
        settings: 'unslick',
      },
    ],
  }

  return (
    <div className={styles.hiw}>
      <div className={styles.wrap}>
        <div className={styles.image}>
          <img {...srcSetProps(sanityImageUrl(howItWorks.image))} className={styles.imageSrc} />
        </div>
        <div className={styles.steps}>
          <h2 className={styles.title}>{howItWorks.title}</h2>
          <Slider {...sliderSettings}>
            {howItWorks.items &&
              howItWorks.items.map((item, index) => (
                <div className={styles.stepsItem} key={item._key}>
                  <div className={styles.stepsIndex}>{index + 1}</div>
                  <div className={styles.stepsText}>
                    <p className={styles.stepsTitle}>{item.title}</p>
                    <p className={styles.stepsInfo}>{item.text}</p>
                  </div>
                </div>
              ))}
          </Slider>
        </div>
      </div>
    </div>
  )
}
