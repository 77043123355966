import React from 'react'
import { srcSetProps, urlWithSearchParamsHandler, sanityImageUrl } from '../../../utils/format'
import styles from './style.module.sass'

export default ({ cta }) => {
  return (
    <div className={styles.cta}>
      <div className={styles.wrap}>
        <div className={styles.content}>
          <h2 className={styles.title}>{cta.title}</h2>
          <p className={styles.text}>{cta.description}</p>
          <a className={styles.link} href={cta.buttonUrl} onClick={urlWithSearchParamsHandler}>
            {cta.buttonText}
          </a>
        </div>
        <div className={styles.image}>
          <img {...srcSetProps(sanityImageUrl(cta.image))} className={styles.imageSrc} />
        </div>
      </div>
    </div>
  )
}
