import React, { useState } from 'react'
import { srcSetProps } from '../../../utils/format'
import { urlWithSearchParamsHandler, prepareParagraph, sanityImageUrl } from '../../../utils/format'
import cx from 'classnames'
import Slider from 'react-slick'
import styles from './style.module.sass'

export default ({ info }) => {
  const [subscriptionActive, setSubscriptionActive] = useState(0)
  const [activeImage, setActiveImage] = useState(info.images?.[0])
  const changeImage = image => {
    setActiveImage(image)
  }

  const sliderSettings = {
    arrows: false,
    dots: true,
    autoplay: false,
    slidesToShow: 1,
    infinite: false,
    dotsClass: styles.dots,
  }

  return (
    <div className={styles.info}>
      <div className={styles.wrap}>
        {activeImage && (
          <>
            <div className={styles.images}>
              {info.images &&
                info.images.map(image => (
                  <div
                    className={cx(styles.imagesItem, {
                      [styles.imagesItemActive]: activeImage === image,
                    })}
                    key={image._key}
                    onClick={() => changeImage(image)}
                  >
                    <img
                      className={styles.imagesItemSrc}
                      {...srcSetProps(sanityImageUrl(image.image))}
                      alt={image.caption}
                    />
                  </div>
                ))}
            </div>
            <div className={styles.image}>
              <img
                className={styles.imageSrc}
                {...srcSetProps(sanityImageUrl(activeImage.image))}
                alt={activeImage.caption}
              />
            </div>

            <div className={styles.slider}>
              <Slider {...sliderSettings}>
                {info.images &&
                  info.images.map((item, index) => (
                    <div
                      className={cx(styles.sliderItem, { [styles.sliderItemActive]: index === 0 })}
                      key={item._key}
                      data-index={index}
                    >
                      <div className={styles.inside}>
                        <img className={styles.insideImage} {...srcSetProps(sanityImageUrl(item.image))} />
                      </div>
                    </div>
                  ))}
              </Slider>
            </div>
          </>
        )}

        <div className={styles.content}>
          <div className={styles.text}>
            <p className={styles.tag}>{info.tag}</p>
            <h1 className={styles.title}>{info.title}</h1>
          </div>
          <div className={styles.contentItem}>
            {info.subscriptionPlanList && info.subscriptionPlanOptions ? (
              <div className={styles.subscription}>
                <div className={styles.subscriptionRow}>
                  <p className={styles.subscriptionTitle}>{info.subscriptionPlanTitle}</p>
                  <p className={styles.subscriptionBest}>Best value</p>

                  <div className={styles.subscriptionBox}>
                    {info.subscriptionPlanOptions.map((plan, index) => (
                      <label className={cx(styles.subItemCol, styles.subItem)} key={plan._key}>
                        <div className={styles.subItemLeft}>
                          <div className={styles.radio}>
                            <input
                              type="radio"
                              name="radio1"
                              onChange={() => setSubscriptionActive(index)}
                              checked={index == subscriptionActive}
                            />
                            <p dangerouslySetInnerHTML={{ __html: plan.monthCount }} />
                          </div>
                        </div>
                        {plan.oldPrice && <div className={styles.subItemMiddle}>{plan.oldPrice}</div>}
                        <div className={styles.subItemRight} dangerouslySetInnerHTML={{ __html: plan.newPrice }} />
                      </label>
                    ))}
                  </div>
                </div>

                <div className={styles.subscriptionRow}>
                  {info.prices &&
                    info.prices.map((price, index) => (
                      <>
                        <p className={styles.subscriptionTitle}>{price.title}</p>
                        <label className={cx(styles.subItem, styles.subItemBorder, styles.subItemNo)}>
                          <div className={styles.subItemLeft}>
                            <div className={styles.radio}>
                              <input type="radio" name="radio2" checked={true} />
                              <p dangerouslySetInnerHTML={{ __html: price.information }} />
                            </div>
                          </div>
                          <div className={styles.subItemRight} dangerouslySetInnerHTML={{ __html: price.price }} />
                        </label>
                      </>
                    ))}
                </div>
              </div>
            ) : (
              <ul className={styles.prices}>
                {info.prices &&
                  info.prices.map((price, index) => (
                    <li className={styles.pricesItem} key={price._key}>
                      <div className={styles.pricesRight}>{price.price}</div>
                      <div className={styles.pricesLeft}>
                        <p className={styles.pricesInfo}>{price.information}</p>
                      </div>
                    </li>
                  ))}
              </ul>
            )}

            <p className={styles.description}>{info.description}</p>
            <ul className={styles.benefits}>
              {info.benefits &&
                info.benefits.map((benefit, index) => (
                  <li key={benefit._key}>
                    <img src={sanityImageUrl(benefit.image)} />
                    <span>{benefit.text}</span>
                  </li>
                ))}
            </ul>
            <a href={info.buttonUrl} onClick={urlWithSearchParamsHandler} className={styles.link}>
              {info.buttonText}
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
